/*******************************
    User Variable Overrides
*******************************/

/* Mana color */
.ui.inverted.menu .mana.active.item,
.ui.inverted.mana.menu {
  background-color: @mana;
}
.ui.inverted.mana .dropdown .menu{
  background-color: @mana !important;
}
.ui.inverted.mana.menu .item:before {
  background-color: @invertedColoredDividerBackground;
}
.ui.inverted.mana.menu .active.item {
  background-color: @invertedColoredActiveBackground !important;
}
