@import url('https://fonts.googleapis.com/css?family=Roboto:700');
@import url('https://fonts.googleapis.com/css?family=Caveat:700');

#Home {

  padding: 1em 0;
  .row {
    padding: 0.5em 0;
  }

  img {
    -webkit-filter: grayscale(60%);
    filter: grayscale(60%);
    transition: all 0.8s;
    box-shadow: 15px 15px 13px lightsteelblue;
    border: groove darkgrey;
    border-radius: 5px;
    width: 120px;
  }
  a {
    //display: block;
  }
  a:hover {
    img {
      opacity: 1;
      filter: grayscale(0);
    }
  }

  /* 3D Transform. Created referring to Dudley Storey's Pen: https://codepen.io/dudleystorey/pen/KFLJp */
  .tdimension {
    margin: auto;
    perspective: 1000px;

    a {
      display: block;
      width: 120px;
      margin: auto;
      height: 100%;
      background-size: cover;
      transform-style: preserve-3d;
      transition: all 0.8s;
    }
    .top {
      transform: rotateX(-55deg);
    }
    .bottom {
      transform: rotateX(55deg);
    }

    a:after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 12px;
      background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1));
      transform: rotateX(90deg);
      transform-origin: bottom;
      .top {
        top: 0;
      }
    }
    a.top:after {
       top: -12px;
    }
    a.bottom:after {
      bottom: 0;
    }
  }

  .tdimension:hover a {
    transform: rotateX(0deg);
  }

  #leitmotiv {
    font-family: "Roboto", Verdana, serif;
    font-size: 2em;

    color:#0456dc;
    text-transform: uppercase;
    font-weight: bold;
    //display: block;
    width: 33% !important;
    #flip {
      padding: .4em 0;
      height: 2em;
      overflow:hidden;
    }

    #flip > div > div {
      color:#fff;
      padding: .25em .5em .25em .25em; // Correction for right-padding due to font
      margin-bottom: 1.45em;
      display:inline-block;
      font-family: "Caveat", Verdana, serif;
    }

    #flip div:first-child {
      animation: show 10s linear infinite;
    }

    #flip div div {
      background:#0456dc;
    }
    #flip div:first-child div {
      background:#7efeff;
    }
    #flip div:last-child div {
      background:#DC143C;
    }
  }

  @keyframes show {
    0% {margin-top:-6em;}
    5% {margin-top:-4.68em;}
    33% {margin-top:-4.68em;}
    38% {margin-top:-2.2em;}
    66% {margin-top:-2.2em;}
    71% {margin-top:0;}
    99.99% {margin-top:0;}
    100% {margin-top:-6em;}
  }

  @media only screen and (max-width: 1100px) {
    div.column:not(.keepUnder1100) {
      display: none;
    }
  }

  @media only screen and (max-width: 800px) {
    div.column:not(.keepUnder800) {
      display: none;
    }
  }

  @media only screen and (max-width: 600px) {
    div.column:not(.keepUnder600) {
      display: none;
    }
  }

  @media only screen and (max-width: 450px) {
    div.column:not(.keepUnder450) {
      display: none;
    }
  }
}
