
body {
  overflow-y: scroll;

  #Announce {
    background-image: linear-gradient(180deg,navy,#85a6f7,#eefeff);
    color: #eefeff;
    height: 22px;
    width: 100%;
    text-shadow: 1px 1px 3px red;
    text-align: center;
    font-family: "Century Gothic", "Trebuchet MS", "Courier New", Courier, monospace;
    font-size: 1.1em;
    font-weight: 650;
    @media only screen and (max-width: 450px) {
      display: none;
    }
  }

  header {
    position: sticky;
    top: 0;
    z-index: 1000;

    .ui.menu {
      border-radius: 0;

      svg {
        position: absolute;
        left: 1.9em;
        width: 1.7em;
      }
    }
  }

  .ui.inverted.mana.menu, .ui.inverted.menu .mana.active.item {
    background-image: linear-gradient(180deg, rgba(73,168,249,1) 1%, rgba(56,254,255,1) 6%, rgba(136,255,255,1) 12%, rgba(5,217,247,1) 26%, rgba(84,128,255,1) 44%, rgba(84,128,255,1) 65%, rgba(133,166,247,1) 83%, rgba(142,197,247,1) 94%, rgba(78,130,232,1) 99%);
    text-shadow: 1px 1px 2px blue;
  }
}


#Games {

  .ui.attached.segment {
    top: 0;
    bottom: 0;
    border-radius: 0;
    width: 100%;
    max-width: calc(100% - -2px);
    box-shadow: 3px 6px 10px 5px lightsteelblue;
    border: 1px solid #d4d4d5;
    border-top: 0;
  }
  .menu {
    .item {
      z-index: 1;
    }
  }

  .items .item {
    padding: 1em 0;
    img {
      box-shadow: 5px 5px 7px darkgrey;
      border: 3px groove goldenrod;
    }
  }

  .meta {
    float: right;
    margin: auto;
  }
}

#Network {
  .items .ui.image {
    width: auto;
    object-fit: none;
    border: 5px double gainsboro;
    box-shadow: 0 0 11px steelblue;
  }
}

#Footer{
  background-image: radial-gradient(ellipse, rgba(4,86,220,1) 9%, rgba(86,212,230,1) 25%, rgba(181,246,255,1) 32%, rgba(119,199,255,1) 42%, rgba(238,254,255,1) 69%);
  .row {
    padding: 0;
  }
  .ui.menu {
    border-radius: 0;
  }
  color: #0456dc;
  #central-column, #Contact, #Twitter {
    color: #b4f5ff;
  }
  #Contact, #Twitter {
    font-family: "Lato","Helvetica Neue",Arial,Helvetica,sans-serif;
  }
  #Contact:hover, #Twitter:hover {
    color: orangered;
  }
}

@media only screen and (max-width: 1000px) {
  header .ui.menu svg {
    display: none !important;
  }
}
