@import "src/mixin-circle";
@import url('https://fonts.googleapis.com/css2?family=Teko&display=swap');

@media only screen and (max-width: 800px) {
  #Content {
  }
  #Services {
    #ExplanationText {
      display: none;
    }
    ul.circle-container {
      border: none;
      height: auto;
      width: auto;
      > * {
        position: relative !important;
        transform: none !important;
        top: auto;
        left: auto;
      }
      li {
        border: none;
        display: inline;
        .service  {
          border: none;
          height: auto;
          background: none;
          .title {
            position: relative !important;
            left: 0 !important;
            right: 0 !important;
            text-align: left;
          }
          .content {
            display: inline-block;
          }
        }
      }
      .value {
        display: none !important;
      }
    }
    #Mana {
      position: fixed !important;
      margin: auto !important;
      left: 0 !important;
      width: auto !important;
    }
  }
}

#Services {

  #Mana {
    opacity: .3;
    -webkit-filter: opacity(55%);
    filter: opacity(55%);
    z-index: -1;
    position: absolute;
    left: 50%;
    width: 35em;
    margin-left: -17.5em;
    margin-top: 0;
  }

  #ExplanationText {
    margin: 2em 0;
  }

  .circle-container {
    @include on-circle($item-count: 6, $circle-size: 35em, $item-size: 3.5em);
    margin: 0 auto;
    border: 2px dotted #ff0505;

    .value {
      border: none;
      width: 70%;
      height: auto;
      text-align: center;
      transform: translate(-42%, -42%);
    }

    li {
      display: block;
      max-width: 100%;
      border-radius: 50%;
      border: 4px double #07bcf3;
      transition: .15s;

      .service {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        -webkit-filter: grayscale(60%);
        filter: grayscale(60%);
        background: radial-gradient(circle, rgba(189,246,254,1) 0%, rgba(5,217,247,1) 18%, rgba(84,87,255,1) 95%, rgba(5,217,247,1) 99%);
        border: outset #02a2ff91;

        &:hover,
        &:active {
          filter: grayscale(0);
          cursor: pointer;
        }

        .title {
          display: block;
          position: absolute;
          font-family: "Agency FB", "Alfphabet", "Teko", Arial, serif;
          font-size: 2em;
          line-height: 1.2em;
          width: max-content;
          text-align: center;
        }
        .title.left {
          right: 2em;
        }
        .title.right {
          left: 2em;
        }

        .content {
          display: none;
        }
      }
    }
  }
}

