
@media only screen and (max-width: 500px) {
  .widescreen {
    display: none !important;
  }
  .smallscreen {
    display: block !important;
  }
}

.widescreen {
  display: block;
}
.smallscreen {
  display: none;
}
