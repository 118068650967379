@media only screen and (max-width: 1200px) {
  #Philosophy {
    #PhilosophyTexts {
      #photoDisplay {
        display: none;
      }

      img {
        display: block !important;
        float: none !important;
        margin: 1em auto !important;
      }
      img.leftOriented, img.leftOriented:hover, img.rightOriented, img.rightOriented:hover, img:hover {
        -webkit-transform: none !important;
        transform: none !important;
      }
    }
  }
}

#Philosophy {
  .message {
    background-color: #089fcd30;
  }

  #PhilosophyTexts {
    img {
      margin: 0 2em;
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transition: .3s ease-in-out;
      transition: .3s ease-in-out;
    }
    img.leftOriented {
      -webkit-transform: rotate(-5deg);
      transform: rotate(-5deg);
    }
    img.rightOriented {
      -webkit-transform: rotate(5deg);
      transform: rotate(5deg);
    }
    img:hover {
      -webkit-transform: rotate(0) scale(1.5);
      transform: rotate(0) scale(1.5);
    }

    .photos {
      padding: 8px;
      border: 1px solid #C8C8C8;
      background-color: #FFF;
      float: left;
    }

    #photoDisplay {
      margin-left: 50px;
    }

    #samplePhoto1 {
      transform:rotate(-10deg);
      -ms-transform:rotate(-10deg); /* IE 9 */
      -moz-transform:rotate(-10deg); /* Firefox */
      -webkit-transform:rotate(-10deg); /* Safari and Chrome */
      -o-transform:rotate(-10deg); /* Opera */
      position: relative;
    }
    #samplePhoto1:hover {
      z-index: 10;
      -webkit-transform: rotate(0) scale(1.5);
      transform: rotate(0) scale(1.5);
    }

    #samplePhoto2 {
      transform:rotate(10deg);
      -ms-transform:rotate(10deg); /* IE 9 */
      -moz-transform:rotate(10deg); /* Firefox */
      -webkit-transform:rotate(10deg); /* Safari and Chrome */
      -o-transform:rotate(10deg); /* Opera */
      position: absolute;
      margin-left: 100px;
    }
    #samplePhoto2:hover {
      z-index: 10;
      -webkit-transform: rotate(0) scale(1.5);
      transform: rotate(0) scale(1.5);
    }

    #samplePhoto3 {
      position: absolute;
      margin-top: 40px;
      margin-left: 5px;
    }

    br {
      clear: both;
      margin: 2em 0;
      display: block;
    }
    .ContainerTextLeft {
      float: left;
    }
    .ContainerTextRight {
      float: right;
    }
  }
}
